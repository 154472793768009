// Copyright 2022 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

import qs from 'qs';

export const useQueryState = query => {
  const location = useLocation();
  const history = useNavigate();

  const setQuery = useCallback(
    value => {
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      const queryString = qs.stringify(
        {...existingQueries, [query]: value},
        {skipNulls: true}
      );

      history(`${location.pathname}?${queryString}`);
    },
    [history, location, query]
  );

  return [
    qs.parse(location.search, {ignoreQueryPrefix: true})[query],
    setQuery,
  ];
};
